//
//  Variables
//  _____________________________________________

$_form--height: 46px;
$_form--border-radius: 0px;
$_form--color: $primary__color;
$_form--border: 2px;
$_form--font-family: $font-family__serif;
$_form--font-size: 16px;
$_form--font-weight: 400;
$_form--padding: 9px;

$form-element-input-placeholder__color: $secondary__color;

//
//  Common (Mobile First)
//  _____________________________________________

label:not(.label) {
    font-size: $_form--font-size;
    font-weight: $_form--font-weight;
    line-height: 48px;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    height: $_form--height;
    border-color: $_form--color;
    padding: 0 $_form--padding;
    border-radius: $_form--border-radius;
    border-width: $_form--border;
    font-weight: $_form--font-weight;
    font-size: $_form--font-size;

}

input[type="number"] {
    padding: 0;
    color: $secondary__color;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"],
input[type="week"] {
    color: $_form--color;
    border: $_form--border solid $_form--color;
    font-weight: $_form--font-weight;
    height: $_form--height;
    font-family: 'Oxygen', sans-serif;
    font-size: $_form--font-size;
    line-height: 1.428571429;
    padding: 0 $_form--padding;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    border-radius: $_form--border-radius;
}

input[type='file'],
input[type='checkbox'],
input[type='radio'] {
    margin: 0 0 1rem;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

textarea {
    min-height: 120px;
    font-weight: $_form--font-weight;
    font-size: $_form--font-size;
    border-width: $_form--border;
    border-radius: $_form--border-radius;
}

select {
    height: $_form--height;
    font-weight: $_form--font-weight;
    font-size: $_form--font-size;
    border-width: $_form--border;
    border-radius: $_form--border-radius;
}

.select {
    display: inline-block;
    position: relative;

    select {
        appearance: none;
        border-radius: $_form--border-radius;
        padding-right: 39px;
    }

    &:after {
        content: "";
        background-color: $_form--color;
        position: absolute;
        width: 9px;
        height: 15px;
        top: calc(50% - (15px / 2));
        right: 15px;
        pointer-events: none;
    }
}

.block.newsletter .field .control:before {
    line-height: $_form--height;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .column:not(.sidebar-additional) form .field-recaptcha,
    .column:not(.sidebar-main) form .field-recaptcha {
        @extend .abs-margin-for-forms-desktop;
    }
}
