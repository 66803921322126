//
//  Colors
//  _____________________________________________

// Theme Colors
$dark-blue: #0a2272;
$light-blue: #ddf9fa;

// Primary and secondary colors
$primary__color: $dark-blue;
$secondary__color: $light-blue;
