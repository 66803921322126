//
//  Common
//  _____________________________________________

.page-header {
    margin-bottom: 0;
    border-bottom: none;

    .panel.wrapper {
        border-bottom: none;
    }

    .header.panel {
        padding-top: 0;
        padding-bottom: 0;
    }

    .switcher {

        .options .action.toggle:after {
            display: none !important;
        }

    }

}

.header.content {

    & > .switcher {
        display: none;
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .page-header {

        .switcher {

            .options {

                &.active {

                    ul.dropdown {

                        a {
                            color: $color-black;
                        }

                    }

                }

                .action.toggle {

                    &::after,
                    &.active::after {

                    }

                }

            }

        }

    }

    .header.content {

        & > .switcher {
            display: inline-block;
        }

    }

    .nav-sections-item-content {

        .switcher {
            display: none;
        }

    }

    .header {

        &.content {
            padding: 16px $indent__base 0;
        }

    }
}
