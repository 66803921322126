//
//  Common
//  _____________________________________________

.cms-index-index {

    .page-main > .page-title-wrapper .page-title {
        max-width: 960px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {


}
