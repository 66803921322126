//
//  Common
//  _____________________________________________

// global reset
h1,
h2,
h3,
h4,
h5,
h6 {
    //padding: 0;
    //margin: 0;
}

h1 {
}

.act-h1 {
    @extend h1;
}

h2 {

}

.act-h2 {
    @extend h2;
}

h3 {
}

.act-h3 {
    @extend h3;
}

h4 {

}

.act-h4 {
    @extend h4;
}

h5 {

}

.act-h5 {
    @extend h5;
}

h6 {

}

.act-h6 {
    @extend h6;
}

p {
    font-size: $font-size__xl;
}

blockquote {

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    h2 {
        @include lib-css(font-size, $h2__font-size-desktop);
    }

    h3 {
        @include lib-css(font-size, $h3__font-size-desktop);
    }

    h4 {
        @include lib-css(font-size, $h4__font-size-desktop);
    }

    h5 {
        @include lib-css(font-size, $h5__font-size-desktop);
    }

    h6 {
        @include lib-css(font-size, $h6__font-size-desktop);
    }

}
