//
//  Form
//  _____________________________________________

$form-element-input__border-color               : $primary__color;
$form-element-input__border                     : 1px solid $form-element-input__border-color;
$form-element-input__border-radius              : 0;
$form-element-input__height                     : 45px;
$form-element-input__padding                    : 0 12px;
$form-element-input__font-size                  : 1.4rem;
$form-element-input__background                 : transparent;

//  Placeholder
$form-element-input-placeholder__color          : $color-black;

//  Disabled state
$form-element-input__disabled__border           : $form-element-input__border;

//  Focus state
$form-element-input__focus__border              : $form-element-input__border;
$form-element-input__focus__background          : $form-element-input__background;

//
//  Input-text
//  ---------------------------------------------

$input-text__background                         : $form-element-input__background;
$input-text__border                             : $form-element-input__border;
$input-text__border-radius                      : $form-element-input__border-radius;
$input-text__height                             : $form-element-input__height;
$input-text__padding                            : $form-element-input__padding;
$input-text__font-size                          : $form-element-input__font-size;

//  Placeholder
$input-text-placeholder__color                  : $form-element-input-placeholder__color;

//  Disabled state
$input-text__disabled__border                   : $form-element-input__disabled__border;

//  Focus state
$input-text__focus__border                      : $form-element-input__focus__border;
$input-text__focus__background                  : $form-element-input__focus__background;

//
//  Select
//  ---------------------------------------------

$select__border                                 : $form-element-input__border;
$select__border-radius                          : $form-element-input__border-radius;
$select__height                                 : $form-element-input__height;
$select__font-size                              : $form-element-input__font-size;

//  Disabled state
$select__disabled__border                       : $form-element-input__disabled__border;

//  Focus state
$select__focus__border                          : $form-element-input__focus__border;

//
//  Textarea
//  ---------------------------------------------

$textarea__background                           : $form-element-input__background;
$textarea__border                               : $form-element-input__border;
$textarea__border-radius                        : $form-element-input__border-radius;
$textarea__font-size                            : $form-element-input__font-size;

//  Placeholder
$textarea-placeholder__color                    : $form-element-input-placeholder__color;

//  Disabled state
$textarea__disabled__border                     : $form-element-input__disabled__border;

//  Focus state
$textarea__focus__border                        : $form-element-input__focus__border;



//
//  Forms
//  ---------------------------------------------

//  Form fieldset
//$form-fieldset-legend__font-size: 18px;
//$form-fieldset-legend__font-weight: $font-weight__light;
//$form-fieldset-legend__margin: 0 0 $indent__base;
//$form-fieldset-legend__padding: 0 0 $indent__s;
//$form-fieldset-legend__width: 100%;
//
////  Form field
//$form-field-type-revert: false;
//$form-field__additional-vertical-indent: $indent__s;
//
////  Form field label
//$form-field-label__font-weight: $font-weight__semibold;
//$form-field-type-label-block__margin: 0 0 8px;
//
////  Form field note icon
//$form-field-note-icon-font__content: false;

$form-element-input-placeholder__color: $secondary__color;
