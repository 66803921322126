//
//  Common
//  _____________________________________________

.footer {

    .bugs,
    .links {

    }

    .links {
        & > li {
            display: inline-block;

            & > a {
                color: $primary__color;
            }
        }
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .footer {

        &.content {

            .links {
                padding: 0;
            }

        }
    }

}
