
//
//  Common
//  _____________________________________________

.nav-toggle {
    display: none;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .navigation {

        .level0 {


            &.active,
            &.has-active {

                & > a:not(.ui-state-active) {

                    span:not(.ui-menu-icon) {

                    }

                }

            }

            & > .level-top {
            }

        }

        li.level0:last-child {
            border-bottom: 0;
        }

    }

    .nav-sections .switcher {
        border-top: none;
        padding: 0;

        li {
            font-size: 2rem;
        }

        &-trigger strong {

            &::after {
                display: none;
            }

        }

        .options {
            display: block;

            .action.toggle {
            }

            ul.dropdown {
                display: block;
                border: none;
                position: relative;
                top: inherit;
                box-shadow: none;

                &::before,
                &::after {
                    display: none;
                }

                li {
                    padding: 8px 40px 8px 15px;

                    &:hover {
                        background: none;
                    }

                    a {
                        color: $secondary__color;
                        padding: 0;
                    }

                }

            }

        }

        &:last-child {
            border-bottom: none;
        }

    }

    .nav-sections-item-title {
        text-align: left;
        border: none;
        width: 100%;
        float: none;
        height: auto;
        background: none;
        display: block !important;
        padding: 8px 40px 8px 15px;
    }

    .nav-sections-item-content {
        padding: 10px 0 20px;
        float: none;
        margin: 0;
        display: block !important;
    }

    .nav-sections-item-switch {
        color: $secondary__color;
        font-weight: 700;
        font-size: 2.4rem;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .sections.nav-sections {
        margin-right: 10px;
    }

    .navigation {
        background: transparent;

        .level0 {

            span {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: -6px;
                    left: 0;
                    width: 100%;
                    transition: transform 0.3s ease-out, border-color 0.3s ease-out;
                    transform: scaleX(0);
                    border-top: 2px solid $color-black;
                }

            }

            &:hover,
            &.ui-state-focus {

                span {
                    &::after {
                        backface-visibility: hidden;
                        transform: scaleX(1);
                    }
                }

            }

            &.active,
            &.has-active {
                > .level-top {
                    display: inline;

                    span {
                        &::after {
                            backface-visibility: hidden;
                            transform: scaleX(1);
                        }
                    }

                }

            }

        }

    }

    .nav-sections {
        display: inline-block;
        position: relative;
        float: right;
        margin-bottom: 16px;
    }

}
