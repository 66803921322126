//
//  Common
//  _____________________________________________

.block-search {
    .action {
        &.search {
            &::before,  {
                color: $text__color;
                font-family: "Font Awesome 5 Free";
                content: "\f002";
            }
            &:hover::before{
                color: $text__color;
            }
        }
    }
    .label{
        &::before,  {
            color: $text__color;
            font-family: "Font Awesome 5 Free";
            content: "\f002";
            font-size: $font-size__l;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .block-search {
        .action {
            &.search {
                padding: 12px 0;
            }
        }
        .nested {
            display: none;
        }
    }

}
