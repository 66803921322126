//
//  Common
//  _____________________________________________

.logo {
    color: $primary__color;
    margin-left: 0;

    img {
        color: $primary__color;
    }
}
