//
//  Buttons
//  _____________________________________________

$button__font-family                          : $font-family__base;
$button__font-weight                          : $font-weight__bold;
$button__line-height                          : 30;
$button__padding                              : 18px 21px;
$button__padding__l                           : $button__padding;
$button__padding-mobile                       : 13.5px 17px;

$button__border-radius                        : 0;

$button__color                                : $color-white;
$button__background                           : $primary__color;
$button__border                               : none;

$button__hover__color                         : $color-white;
$button__hover__background                    : $primary__color;
$button__hover__border                        : none;

$button__active__color                        : $button__hover__color;
$button__active__background                   : $button__hover__background;
$button__active__border                       : $button__hover__border;

//  Small button
$button__font-size__s                         : 14px;
//$button__line-height__s                       : $line-height__s;
$button__padding__s                           : $button__padding-mobile;


//
//  Buttons
//  ---------------------------------------------

$button__background: $primary__color;
$button__color: $color-white;
$button__border: 1px solid $primary__color;
$button__hover__color: $button__color;
$button__hover__border: 1px solid $primary__color;
$button__hover__background: $primary__color;
$button__active__background: $primary__color;
$button__font-weight: 700;
$button__font-size: 1.6rem;

//  Primary button
$button-primary__background: $primary__color;
$button-primary__border: 1px solid $primary__color;
$button-primary__color: $button__color;

$button-primary__hover__background: $primary__color;
$button-primary__hover__border: 1px solid $primary__color;
$button-primary__hover__color: $button__color;
$button-primary__active__background: $primary__color;
$button-primary__active__border: 1px solid $primary__color;
$button-primary__active__color: $button__color;

//  Secondary button
$button-secondary__background: $secondary__color;
$button-secondary__border: 1px solid $secondary__color;
$button-secondary__color: $button__color;

$button-secondary__hover__background: $secondary__color;
$button-secondary__hover__border: 1px solid $secondary__color;
$button-secondary__hover__color: $button__color;
$button-secondary__active__background: $secondary__color;
$button-secondary__active__border: 1px solid $button__color;
$button-secondary__active__color: $button__color;

//  Button with icon
//$button-icon__line-height: $icon-font__line-height;
