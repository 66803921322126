/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.mr-10 which gives margin-right 10 pixels.
.mr-20 gives MARGIN to the RIGHT 20 pixels.
.mt-20 gives MARGIN to the TOP 20 pixels and so on.
.pb-10 gives PADDING to the BOTTOM of 10 pixels
.pl-40 gives PADDING to the LEFT of 40 pixels
.pa-10 giver PADDING to ALL sides of 10 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 200, 250, 300, 350, 400, 450, 500); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .ma-#{$space} {
            margin: #{$space}px !important;
        }

        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }

        .pa-#{$space} {
            padding: #{$space}px !important;
        }

        @include min-screen($screen__m) {
            .desktop-ma-#{$space} {
                margin: #{$space}px !important;
            }

            .desktop-m#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important;
            }

            .desktop-p#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important;
            }

            .desktop-pa-#{$space} {
                padding: #{$space}px !important;
            }
        }

        @include min-screen($screen__l) {
            .desktop-large-ma-#{$space} {
                margin: #{$space}px !important;
            }

            .desktop-large-m#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important;
            }

            .desktop-large-p#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important;
            }

            .desktop-large-pa-#{$space} {
                padding: #{$space}px !important;
            }
        }

        @include max-screen($screen__m) {
            .mobile-ma-#{$space} {
                margin: #{$space}px !important;
            }

            .mobile-m#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important;
            }

            .mobile-p#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important;
            }

            .mobile-pa-#{$space} {
                padding: #{$space}px !important;
            }
        }
    }
}
