//
//  Typography
//  ---------------------------------------------

//  Font families
$font-family-name__base		    : 'Raleway';
$font-family__base              : $font-family-name__base, sans-serif;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace;
$heading__font-family-name__base: 'Playfair Display';
$heading__font-family__base		: $heading__font-family-name__base, serif;

$font-size__base                : 16px;
$font-size__l                   : 20px;
$font-size__xl                  : 24px;
$font-size__s                   : 14px;

//  Weights
$font-weight__light             : 300;
$font-weight__regular           : 400;
$font-weight__heavier           : 500;
$font-weight__semibold          : 600;
$font-weight__bold              : 700;
$font-weight__heavy             : 800;

//  Colors
$text__color                    : $color-black;

//  Indents
$indent__xxl                    : $indent__base * 3; // 60px

//
//  Body
//  ---------------------------------------------

$text__color                    : $primary__color;
//$font-size-ratio__base        : 1.4;
//$font-style__base             : normal;
$line-height__base              : (20/14) * 1.5;

//
//  Borders
//  ---------------------------------------------
$border-color__base             : $primary__color;
$border-color__secondary        : $color-gray80;
$hr__border-color               : rgba($border-color__base, 0.25) !important;

//
//  Links
//  ---------------------------------------------

$link__color                    : $primary__color;
$link__text-decoration          : underline;

$link__visited__color           : $link__color;
$link__visited__text-decoration : none;

$link__hover__color             : $link__color;
$link__hover__text-decoration   : none;

$link__active__color            : $link__color;
$link__active__text-decoration  : underline;

//
//  List
//  ---------------------------------------------

$list__margin-bottom: 0;

//
//  Headings
//  ---------------------------------------------

$heading__color__base           : $primary__color;
$heading__font-weight__base     : $font-weight__bold;
$heading__margin-top__base      : 0;
$heading__margin-bottom__base   : 0;


$h1__font-size                  : 36;
$h1__font-color                 : $heading__color__base;
$h1__font-family                : $heading__font-family__base;
$h1__margin-bottom              : $indent__s;
$h1__font-weight                : $heading__font-weight__base;
$h1__font-size-desktop          : 9.1rem;
$h1__line-height                : $line-height__s;

$h2__font-size                  : 24;
$h2__font-color                 : $heading__color__base;
$h2__font-family                : $heading__font-family__base;
$h2__font-weight                : $heading__font-weight__base;
$h2__font-size-desktop          : 3.6rem;
$h2__margin-bottom              : $heading__margin-bottom__base;
$h2__margin-top                 : $heading__margin-top__base;
$h2__line-height                : $line-height__s;

$h3__font-size                  : 20;
$h3__font-color                 : $heading__color__base;
$h3__font-family                : $heading__font-family__base;
$h3__font-weight                : $heading__font-weight__base;
$h3__font-size-desktop          : 2.4rem;
$h3__margin-bottom              : $indent__s;
$h3__line-height                : $line-height__s;

$h4__font-size                  : 18;
$h4__font-color                 : $heading__color__base;
$h4__font-family                : $heading__font-family__base;
$h4__font-weight                : $heading__font-weight__base;
$h4__font-size-desktop          : 2.0rem;
$h4__margin-bottom              : $indent__s;
$h4__line-height                : $line-height__s;

$h5__font-size                  : 16;
$h5__font-color                 : $heading__color__base;
$h5__font-family                : $heading__font-family__base;
$h5__font-weight                : $heading__font-weight__base;
$h5__font-size-desktop          : 1.8rem;

$h6__font-size                  : 14;
$h6__font-color                 : $heading__color__base;
$h6__font-family                : $heading__font-family__base;
$h6__font-weight                : $heading__font-weight__base;
$h6__font-size-desktop          : 1.6rem;
