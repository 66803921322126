//
//  Common
//  _____________________________________________

.product-label-wrapper {

    .product-label {
        background-color: $primary__color;
        color: $color-white;
    }
}

//
//  Desktop
//  _____________________________________________

//@include min-screen($screen__m) {}
