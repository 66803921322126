//
//  Common
//  _____________________________________________

.cookie-status-message {
    display: none;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-main {
        padding: 0 $indent__base;
    }
}
