//
//  Animations
//  ---------------------------------------------

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes move-up-down {
    0%, 100% {
        bottom: 0;
    }
    50% {
        bottom: 3px;
    }
}

@keyframes shake-right {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(3px, 0, 0);
        transform: translate3d(3px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes shake-left {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(-3px, 0, 0);
        transform: translate3d(-3px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes shake-left-rotated {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0) rotate(180deg);
        transform: translate3d(0, 0, 0) rotate(180deg);
    }
    50% {
        -webkit-transform: translate3d(-3px, 0, 0) rotate(180deg);
        transform: translate3d(-3px, 0, 0) rotate(180deg);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) rotate(180deg);
        transform: translate3d(0, 0, 0) rotate(180deg);
    }
}

@keyframes shake-top {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(0, -3px, 0);
        transform: translate3d(0, -3px, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes shake-left-bottom {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(-3px, 3px, 0);
        transform: translate3d(-3px, 3px, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
