//
//  Navigation
//  ---------------------------------------------

$navigation__background: $color-white;
$navigation-desktop__background: transparent;
$navigation-desktop__font-size: 16px;

//$submenu-item__active__color: #5B8E30;

//  Desktop navigation
//$navigation-desktop-level0-item__color: lighten($color-black, $brand-color__opacity-1);
//$navigation-desktop-level0-item__line-height: 32px;
$navigation-desktop-level0-item__hover__color:  $color-black;
$navigation-desktop-level0-item__active__color: $color-black;
$navigation-desktop-level0-item__active__background: transparent;
$navigation-desktop-level0-item__active__border-width: 0;

$submenu-desktop-item__color: $color-black;
$submenu-desktop-item__active__color: $color-black;

$submenu-desktop-item__active__color: $primary__color;
$navigation-desktop-level0-item__active__border-color: $primary__color;
