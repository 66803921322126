//
//  Common
//  _____________________________________________

.minicart-wrapper {
    .action {
        &.showcart {
            &::before {
                color: $text__color;
                font-family: "Font Awesome 5 Free";
                content: "\f291";
                font-size: $font-size__xl;
                padding: $indent__xs 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

}
