//
//  Common
//  _____________________________________________

.swiper-pagination-bullet-active {
    background: $primary__color;
}

// fix row/swiper-wrapper bug
.swiper-wrapper.row {
    flex-wrap: unset;
    box-sizing: content-box;
}

#recommendations,
.blog-recent-widget,
.usp {
    position: relative;
    overflow: hidden;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    // homepage recommandations
    #recommendations,
    .blog-recent-widget,
    .usp {

        // hide pagination on desktop
        .swiper-pagination {
            display: none;
        }

    }

}
