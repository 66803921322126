//
//  Breadcrumbs
//  ---------------------------------------------

$breadcrumbs__container-margin                  : 0 0 15px;
$breadcrumbs__font-size                          : 1.4rem;
$breadcrumbs-icon__use                           : false;

//  Current page
$breadcrumbs-current__color                      : $color-black;
$breadcrumbs-current__font-weight                : $font-weight__bold;

$breadcrumbs-separator__symbol                   : "/";
$breadcrumbs-separator__color                    : $color-black;

//
//  Link
//  ---------------------------------------------

$breadcrumbs-link__color                         : $color-black;
$breadcrumbs-link__visited__color                : $color-black;
$breadcrumbs-link__hover__color                  : $color-black;
$breadcrumbs-link__active__color                 : $color-black;
