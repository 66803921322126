@mixin callout($type: false, $solid: false) {
    position: relative;
    margin: 0 0 1rem 0;
    padding: 20px 3rem;
    border: 3px solid $primary__color;
    border-radius: 10px;

    @if $type == "primary" and $solid == true {
        background-color: $primary__color;
        color: $color-white;
    }

    @if $type == "secondary" {
        border: 3px solid $primary__color;

        @if $solid == true {
            background-color: $primary__color;
            color: $color-white;
        }
    }

    @include min-screen($screen__m) {
        padding: 80px 3rem;
    }
}
