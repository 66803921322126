//
//  Common
//  _____________________________________________

.callout {
    @include callout();

    &.primary {

        &.solid {
            background-color: $primary__color;
            color: $color-white;
        }

    }

    &.secondary {
        border: 3px solid $primary__color;

        &.solid {
            background-color: $primary__color;
            color: $color-white;
        }
    }

}
