//
//  Common
//  _____________________________________________

.tooltip {

    &-top {
        @include lib-tooltip(top);
    }

    &-content {
        font-size: $font-size__base;
        font-family: $font-family-name__base;
    }

    &-toggle {
        color: $text__color;
        text-decoration: none;

        &:hover,
        &:visited {
            color: $text__color;
            text-decoration: none;
        }
    }

}

//
//  Desktop
//  _____________________________________________

//@include min-screen($screen__m) {}
