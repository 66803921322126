//
//  Variables
//  _____________________________________________

//
//  Common
//  _____________________________________________

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary(
        $_button-padding: $button__padding
    );
}

.action.secondary {
    @extend .action.primary;
    background-color: $color-white;
    color: $text__color;
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

}
