//
//  Layout
//  ---------------------------------------------

$page__background-color             : $secondary__color;
$layout__max-width                  : 1600px;
$layout-indent__width               : 30px;
$layout__width-xs-indent            : $layout-indent__width;

//  Variables used for layout grid
$total-columns: 12;
$gutter-width: 0;
