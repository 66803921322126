//
//  Common
//  _____________________________________________

.message {

    &.global {
        p {
            font-size: $font-size__s;
        }
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .message {

        &.global {
            p {
                font-size: $font-size__base;
            }
        }

    }


}
