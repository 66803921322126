//
//  Variables
//  _____________________________________________

$socials: facebook, youtube, linkedin, spotify, whatsapp, github, stack-overflow; /* Used in @each */
$social-icon_size: 32px;

//
//  Common
//  _____________________________________________

.widget-social {

    .social {
        @include list-unstyled;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 ($indent__l / 4 * -1);
        padding: ($indent__l / 4);

        &__item {
            margin: 0;
            padding-right: ($indent__l / 2);
            padding-left: ($indent__l / 2);
            line-height: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__link {
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;;
            border-radius: ($social-icon_size / 2);
            height: $social-icon_size;
            width: $social-icon_size;
            color: $text__color;

            i {
                font-size: $social-icon_size / 4 * 3;
            }
        }

        &__title {
            display: none;
        }

        @each $social in $socials {
            &__link--#{$social} {
                &:before {
                    font-family: 'Font Awesome 5 Free';
                }

                &:hover {
                    &:before {
                        animation: shake-top 0.5s linear both;
                    }
                }
            }

            &__title {
                display: none;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .widget-social {
        .social {
            i {
                font-size: $social-icon_size;
            }
        }
    }
}

