@include min-screen($screen__m) {
    .hide-desktop {
        display: none !important;
    }
}

@include max-screen($screen__m) {
    .hide-mobile {
        display: none !important;
    }
}
