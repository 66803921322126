//
//  Common
//  _____________________________________________

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .product.data.items {
        margin: 0 auto;
        padding: 0;
        float: none;
        text-align: center;
        color: #666;

        & > .item.title {

            position: relative;
            margin-right: 50px;
            overflow: hidden;
            float: none;
            display: inline-block;

            &.active:before {
                width: 100%;
            }

            &:before {
                content: '';
                height: 3px;
                background: #333;
                left: 0;
                position: absolute;
                width: 0;
            }

            & > .switch {

                text-transform: uppercase;
                border: 0;
                font-weight: 600;
                line-height: inherit;
                font-size: 16px;
                color: #bfbfbf;
                text-decoration: none;
                background: none !important;
                height: inherit;
                padding: 20px 0 !important;

                &:visited {
                    color: #7d7d7d;
                    text-decoration: none;
                }
            }

            & > .switch:active,
            &.active > .switch,
            &.active > .switch:focus,
            &.active > .switch:hover {
                background: #fff;
                color: #333;
            }

            &:not(.disabled) > .switch:focus,
            &:not(.disabled) > .switch:hover {
                color: #7d7d7d;
                text-decoration: none;
            }
        }

        & > .item.content {
            text-align: left;
            border-top: none;
            padding-right: 0;
            padding-left: 0;
            padding-top: 44px;
        }

    }

}
