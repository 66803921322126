%svg-common {
	background: url("svg/sprite.css.svg") no-repeat;
}

.icon-icon-pepper {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-icon-pepper-dims {
	width: 50px;
	height: 50px;
}

.icon-icon-pepper-1 {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-icon-pepper-1-dims {
	width: 35px;
	height: 51px;
}

.icon-icon-pepper-1-filled {
	@extend %svg-common;
	background-position: 29.914529914529915% 0;
}

.icon-icon-pepper-1-filled-dims {
	width: 35px;
	height: 51px;
}

.icon-icon-pepper-2 {
	@extend %svg-common;
	background-position: 58.333333333333336% 0;
}

.icon-icon-pepper-2-dims {
	width: 32px;
	height: 51px;
}

.icon-icon-pepper-2-filled {
	@extend %svg-common;
	background-position: 0 100%;
}

.icon-icon-pepper-2-filled-dims {
	width: 32px;
	height: 51px;
}

.icon-icon-pepper-3 {
	@extend %svg-common;
	background-position: 27.35042735042735% 100%;
}

.icon-icon-pepper-3-dims {
	width: 35px;
	height: 51px;
}

.icon-icon-pepper-3-filled {
	@extend %svg-common;
	background-position: 57.26495726495727% 100%;
}

.icon-icon-pepper-3-filled-dims {
	width: 35px;
	height: 51px;
}

.icon-icon-pepper-filled {
	@extend %svg-common;
	background-position: 100% 96.15384615384616%;
}

.icon-icon-pepper-filled-dims {
	width: 50px;
	height: 50px;
}

