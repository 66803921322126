//
//  Buttons
//  _____________________________________________

@mixin btn-defaults() {
    font-family: $font-family__base;
    text-decoration: none;
    border-radius: $button__border-radius;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include max-screen($screen__m) {
        padding: $button__padding-mobile;
    }
}

@mixin btn(
    $size: default
) {
    @if $size == large {
        @include lib-button-l();
    } @else if $size == small {
        @include lib-button-s();
    } @else{
        @include  lib-button();
    }
    @include btn-defaults;

    transition: background-position 1s;
    background-image: linear-gradient(110deg, $primary__color 50%, $primary__color__lighter 50%);
    background-size: 500% 500%;
    background-position: 0 0;

    &:hover {
        background-image: linear-gradient(110deg, $primary__color 50%, $primary__color__lighter 50%);
        background-size: 500% 500%;
        background-position: 100% 100%;
    }
}

@mixin btn-override(
    $size: default,
    $color: $color-gray80,
    $background: $color-gray80,
    $border-color: $color-gray80
) {
    @include btn($size);
    background: $background;
    border-color: $border-color;

    &,
    &:visited {
        color: $color;
    }

    &:before {
        color: $color !important;
    }

    &:hover,
    &:active,
    &:focus {
        background: transparent;
        border-color: $color;

        &,
        &:visited {
            color: $color;
        }

        &:before {
            color: $color !important;
        }
    }
}


@mixin btn-secondary(
    $size: default,
    $color: $primary__color,
    $background: $color-white,
    $border-color: $color-gray80
) {
    @include btn-override(
        $size,
        $color,
        $background,
        $border-color
    )
}

@mixin btn-primary() {
    @include lib-button-primary();
    @include btn-defaults;
}

@mixin btn-icon(
    $icon: 'arrow-white',
    $icon-hover: 'arrow-white',
    $icon-margin: 0 0 0 $indent__s,
    $icon-animation: '',
    $icon-background: $color-hover-blue,
    $size: default,

) {
    @if $size == default {
        padding-right: 54px + 21px !important;
    }  @else{
        padding-right: 40px + 2px !important;
    }
    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        background-color: $icon-background;
        @if $size == default {
            width: 54px;
        } @else{
            width: 40px;
        }
    }

    &:after {
        @include icon(
            $icon: $icon
        );
        position: absolute;
        margin: $icon-margin;
        top: 50%;
        @if $size == default {
            right: (54px / 2);
        } @else {
            right: (40px / 2);
        }

        transform: translate(50%, -50%);
    }

    &:hover {
        &:after {
            @include icon(
                $icon: $icon-hover
            );
            animation: $icon-animation;
        }
    }
    @include btn-defaults;
}

@mixin lib-link-as-button() {
    @include lib-css(display, $button__display);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

//
//  Button as link
//  ---------------------------------------------

@mixin lib-button-as-link(
    $_link-color       : $link__color,
    $_link-color-hover : $link__hover__color,
    $_line-height      : $line-height__base,
    $_disabled_opacity : $button__disabled__opacity,
    $_margin           : 0,
    $_padding          : 0
) {
    @include lib-css(line-height, $_line-height);
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    @include lib-link();
    background: none;
    border: 0;
    display: inline;
    box-shadow: none;

    &:hover {
        @include lib-css(color, $_link-color-hover);
    }

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        @include lib-css(color, $_link-color);
        @include lib-css(opacity, $_disabled_opacity);
        cursor: default;
        pointer-events: none; // Disabling of clicks
        text-decoration: underline;
    }
}
