//
//  Common
//  _____________________________________________

.block.newsletter {

    .label {
        //@include lib-visually-hidden-reset;
    }

    .field {
        .control {
            &::before {
                color: $text__color;
                font-family: "Font Awesome 5 Free";
                content: "\f0e0";
                font-size: $font-size__l;
                margin-left: $indent__s;
            }
        }
    }

    .action.subscribe {
        border-radius: 0;
    }

    input {
        &::placeholder {
            color: $text__color;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .block.newsletter {
    }
}
