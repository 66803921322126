//
//  Common
//  _____________________________________________

.widget-slider {

    &--full-page {

    }

    .swiper-container {
        width: 100vw;
        height: 100vh;

        .swiper-slide {
            background-position: center;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper-text {


            .swiper-text-header {


            }

            .swiper-text-content {

            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
}
