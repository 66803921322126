//
//  Common
//  _____________________________________________

.footer {

    &.content {
        max-width: $layout__max-width - ($indent__base * 2);
        //border-top-color: $primary__color;
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .footer {

        &.content {

        }
    }

}
